// globals
var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    this.para_ele = document.querySelectorAll("." + this.el);
    this.para_ele.forEach((ele, index)=> {
      if(ele.classList.contains('js-parallax--reverse')) {
        this.translateY = '20px';
      } else {
        this.translateY = '-20px';
      }
      ele.classList.add(this.el + index);
      gsap.to('.' + this.el + index + ' img', {
        translateY: this.translateY,
        scrollTrigger: {
          trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })
  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}

//SmoothScroll
class SmoothScroll {
  constructor(){
    this.event();
  }
  event(){
    var headerHeight = $(".l-header")[0].offsetHeight;     //fixedのヘッダーの高さを取得
    var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 500;   //スクロールのアニメーションスピード
    // if(urlHash) {
    //   $('body,html').stop().scrollTop(0);
    //   setTimeout(function(){
    //     var target = $(urlHash);
    //     var position = target.offset().top - headerHeight;
    //     $('body,html').stop().animate({scrollTop:position}, animeSpeed);
    //   }, 100);
    // }
    $('a[href*="#"]').on({
      'click': function(){
        var href = $(this).attr("href");
        if(href.charAt(0) == "#") {
          var target = $(href);
        } else {
          href = href.split('#')
          var target = $('#' + href[1]);
        }
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }
    });
  }
}



(() => {
  let smoothScroll = new SmoothScroll();
  let imgParallax = new ImgParallax('js-parallax');
  let inviewEvent = new InviewEvent('.js-inview');
  
  if($(".s-loading").length) {
    var tl = gsap.timeline();
  
    tl.to(".s-loading__char01", 2, {
      ease: Power0.easeNone,
      scale: 1,
      rotate: 300,
      onStart: function() {
        $('html').toggleClass('is-hidden');
        $('html').css({position: 'fixed'});
        setTimeout(function() {
          $('html').css({position: 'inherit'});
        }, 10)
      }
    });
  
    tl.to(".s-loading__char01", .5, {
      opacity: 1,
    }, "=>");
  
    tl.to(".s-loading__char01", .5, {
      opacity: 0,
    }, "=-1");
  
    tl.to(".s-loading__logo", .5, {
      opacity: 1,
    }, "=-1");
  
    tl.to(".s-loading__char02 img:nth-of-type(1)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__char02 img:nth-of-type(2)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__char02 img:nth-of-type(3)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__char02 img:nth-of-type(4)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__char02 img:nth-of-type(5)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__char02 img:nth-of-type(6)", .8, {
      ease: Back.easeOut,
      y: "0%",
    }, "=-.6");
  
    tl.to(".s-loading__logo, .s-loading__bg", .5, {
      opacity: 0,
      onComplete: function() {
        $('html').toggleClass('is-hidden');
        $('.s-loading').css({pointerEvents: 'none', zIndex: '3', position: 'absolute', overflow: 'inherit'});

        var swiper = new Swiper(".swiper", {
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          slidesPerView: 1,
          spaceBetween: 15,
          loop: true,
          speed: 2000,
          breakpoints: {
            // スライドの表示枚数：500px以上の場合
            960: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            }
          }
        });
      }
    }, "=+1");
  }


  let gEl = ['.s-mv__marquee02', ".s-b-marquee02"]

  gEl.forEach(function(el) {
    gsap.to(el, {
      // yes, we can add it to an entire timeline!
      x: "-50rem",
      scrollTrigger: {
        trigger: el,
        start: "top bottom", // when the top of the trigger hits the top of the viewport
        end: "bottom top", // end after scrolling 500px beyond the start
        scrub: 5, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
      }
    });
  })
  
  $(window).scroll(function() {
    if($(this)[0].scrollY > 50) {
      $(".js-header").addClass('is-scroll');
    } else {
      $(".js-header").removeClass('is-scroll');
    }
  })

  $('.js-drawer, .l-header__right a').click(function() {
    if (window.matchMedia( "(max-width: 959px)" ).matches) {
      $('.js-header').toggleClass('is-active');
      $('html').toggleClass('is-hidden');
    }
  })

  $(".js-f-aco").click(function() {
    $(this).toggleClass('is-active').parent().next().slideToggle();
    return false;
  })
})();

//images resources loaded
$(window).on('load', () => {
  $(window).trigger('loading');
});